

















import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourCard from '@/components/Mobile/TourCard.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Scaffold, TourCard },
  async mounted() {
    this.$store.dispatch('toggleLoading', true);
    await this.$store.dispatch('tour/fetchUserSavedTourIds');
    await this.$store.dispatch('tour/bindToursRef');
    this.$store.dispatch('toggleLoading', false);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      savedTours: 'tour/getSavedTours',
      user: 'auth/getUser',
      savedTourIds: 'tour/getSavedTourIds',
    }),
  },
  methods: {},
});
